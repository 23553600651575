import { useState } from 'react';

const useSessionStorage = (key, initialData) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            console.log(item ? JSON.parse(item) : initialData);
            // Parse stored json or if none return initialData
            const storedItems = item ? JSON.parse(item) : initialData;
            window.localStorage.setItem(key, JSON.stringify(storedItems));
            return storedItems;
        } catch (error) {
            console.error("Error reading from localStorage", error);
            return initialData;
        }
    });

    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error("Error saving to localStorage", error);
        }
    };

    const updateSession = (sessionId, newSessionData) => {
        setValue((prevSessions) => ({
            ...prevSessions,
            [sessionId]: { ...prevSessions[sessionId], ...newSessionData }
        }));
    };

    const addSession = (newSession) => {
        setValue((prevSessions) => ({
            ...prevSessions,
            [newSession.id]: newSession
        }));
    };

    const deleteSession = (sessionId) => {
        setValue((prevSessions) => {
            const { [sessionId]: _, ...remainingSessions } = prevSessions;
            return remainingSessions;
        });
    };

    return [storedValue, updateSession, addSession, deleteSession];
};

export default useSessionStorage;
