import React from 'react';
import { makeStyles } from '@mui/styles';
import CustomTreetButtonHollow from '../Buttons/CustomTreetButtonHollow.jsx'; // Your custom button component
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    petsListContainer: {
        "&.MuiGrid-root": {
            display: 'flex',
            alignItems: 'center',
            alignContent: 'flex-start',
        },
        paddingBottom: '70px',

    },
    petsValue: {
        color: 'var(--Bright-Purple, #7019FF)',
        paddingLeft: '12px',
    },

    newPetButtonWrapper: {
        // center the button at the bottom of the screen
        display: 'flex',
        position: 'fixed',
        bottom: '0dvh',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        padding: '16px 24px',
        width: "fit-content",
        justifyContent: "center",
        alignItems: 'center',

    },
    noPetsContainer: {
        position: 'absolute',  // Added to center the message like in PetList
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'fit-content',
        width: 'fit-content',
        color: '#7D7D97',
    },
}));

const UpcomingList = ({ pets, createNewPet, handlePetDelete, handlePetUpdate }) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.newPetButtonWrapper}>
                <CustomTreetButtonHollow
                    icon="Add"
                    onClick={() => console.log('New Reminder')}
                    variant="filled"
                    type="pet"
                    style={{
                        backgroundColor: 'var(--White, #FFFFFF)',
                        boxShadow: '0px 2px 35px 0px #00000026',
                        // maxWidth: '132px',
                        maxHeight: '40px',
                        padding: '0px 20px 0px 12px',
                        gap: '6px',
                        borderRadius: '55px',
                    }}
                >
                    New Reminder
                </CustomTreetButtonHollow>
            </div>

            <div className={classes.noPetsContainer}>
                <img src={`${process.env.PUBLIC_URL}/logo-gray.svg`} alt='logo' />
                <Typography>You’re all caught up</Typography>
            </div>
        </>
    );
};

export default UpcomingList;
