import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Tabs, Tab, Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import EditableText from '../EditableText/EditableText';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import MicNoneIcon from '@mui/icons-material/MicNone';
import { paths } from '../../data/constants';

const useStyles = makeStyles((theme) => ({
    header: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        width: '100dvw',
    },
    title: {
        "&.MuiTypography-root": {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24.38px',
        },
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            textAlign: 'center',
            alignItems: 'center',
        }
    },
    backButton: {
        '&.MuiButtonBase-root': {
            [theme.breakpoints.down('sm')]: {
                marginRight: 'auto',
            }
        }
    },
    tabsContainer: {
        flexGrow: 1,
    },
    tabContent: {
        padding: theme.spacing(2),
    },
    tabTitle: {
        '&.MuiTab-root': {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '17.07px',
        }
    },
    sessionHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '16px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },
    dummySpace: {
        width: '40px', // same width as backButton to balance the space
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
        }
    },
}));

const SessionHeader = ({ session, handleSessionUpdate, setTabIndex, tabIndex, isSmallScreen }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = React.useState(false);

    const handleBackClick = () => {
        navigate(paths.notes);
    };

    const handleSave = (newTitle) => {
        handleSessionUpdate(session.id, {title : newTitle});
        setIsEditing(false);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    return (
        <div className={classes.sessionHeaderContainer}>
            <div className={classes.titleContainer}>
                <IconButton onClick={handleBackClick} className={classes.backButton}>
                    <ArrowBackIcon />
                </IconButton>
                <EditableText
                    text={session?.title || 'Untitled'}
                    clickable={true}
                    className={classes.title}
                    onSave={handleSave}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
                {isSmallScreen && <div className={classes.dummySpace} />}
            </div>
            {isSmallScreen && (
                <div className={classes.tabsContainer}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab className={classes.tabTitle} label="Transcript" icon={<MicNoneIcon />} iconPosition={'start'} />
                        <Tab className={classes.tabTitle} label="Summary" icon={<AutoAwesomeIcon />} iconPosition={'start'} />
                    </Tabs>
                </div>
            )}
        </div>
    );
};

export default SessionHeader;
