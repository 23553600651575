// export const pets = {
//     1: {
//         id: 1,
//         name: 'Buddy',
//         breed: 'Golden Retriever',
//         age: 5,
//         weight: 65,
//         livesIn: 'New York, NY',
//         personality: 'Friendly, Energetic, Playful',
//         favFood: 'Chicken, Carrots, Apples',
//         favActivity: 'Playing fetch, Going on long walks',
//         fear: 'Thunder, Fireworks',
//         avatar: 'https://images.dog.ceo/breeds/segugio-italian/n02090722_002.jpg',
//         sex: "Male",
//         type: "Dog",
//         petID: {
//             chipNumber: '123456789',
//             animalID: '123456789',
//             spayed: true,
//             vaccines: true
//         },
//         parents: [1, 2],
//     },
//     2: {
//         id: 2,
//         name: 'Whiskers',
//         breed: 'Siamese',
//         age: 2,
//         weight: 10,
//         livesIn: 'New York, NY',
//         personality: 'Independent, Curious, Playful',
//         favFood: 'Fish, Chicken, Catnip',
//         favActivity: 'Exploring, Napping',
//         fear: 'Loud noises, Strangers',
//         avatar: 'https://i.pinimg.com/736x/0c/82/fb/0c82fb72d66bd68a7175ac12a95267fc.jpg',
//         sex: "Female",
//         type: "Cat",
//         petID: {
//             chipNumber: '123456789',
//             animalID: '123456789',
//             spayed: true,
//             vaccines: true
//         },
//         parents: [1, 2],
//     },
//     3: {
//         id: 3,
//         name: 'Fluffy',
//         breed: 'Persian',
//         age: 3,
//         weight: 15,
//         livesIn: 'New York, NY',
//         personality: 'Calm, Affectionate, Playful',
//         favFood: 'Chicken, Salmon, Catnip',
//         favActivity: 'Napping, Grooming',
//         fear: 'Loud noises, Strangers',
//         avatar: '', // No avatar provided
//         sex: "Female",
//         type: "Cat",
//         petID: {
//             chipNumber: '987654321',
//             animalID: '987654321',
//             spayed: true,
//             vaccines: false,
//         },
//         parents: [3],
//     },
//     4: {
//         id: 4,
//         name: 'Mittens',
//         breed: 'Maine Coon',
//         sex: "Female",
//         type: "Cat",
//         avatar: 'https://example.com/images/maine-coon.jpg',
//     },
//     5: {
//         id: 5,
//         name: 'Rex',
//         breed: 'German Shepherd',
//         age: 6,
//         weight: 70,
//         livesIn: 'Los Angeles, CA',
//         personality: 'Loyal, Intelligent, Protective',
//         favFood: 'Beef, Chicken, Bones',
//         favActivity: 'Guarding, Running',
//         fear: 'Water',
//         avatar: 'https://images.dog.ceo/breeds/germanshepherd/n02106662_5170.jpg',
//         sex: "Male",
//         type: "Dog",
//         petID: {
//             chipNumber: '555666777',
//             animalID: '555666777',
//             spayed: false,
//             vaccines: true
//         },
//         parents: [5, 6],
//     },
//     6: {
//         id: 6,
//         name: 'Bella',
//         breed: 'Labrador Retriever',
//         age: 4,
//         weight: 55,
//         livesIn: 'Seattle, WA',
//         personality: 'Playful, Friendly, Gentle',
//         favFood: 'Fish, Chicken, Peanuts',
//         favActivity: 'Swimming, Fetch',
//         avatar: 'https://images.dog.ceo/breeds/labrador/n02099712_757.jpg',
//         sex: "Female",
//         type: "Dog",
//         petID: {
//             chipNumber: '999888777',
//             animalID: '999888777',
//             spayed: true,
//             vaccines: true
//         },
//     },
//     7: {
//         id: 7,
//         name: 'Coco',
//         breed: 'Poodle',
//         age: 7,
//         weight: 20,
//         livesIn: 'Austin, TX',
//         personality: 'Energetic, Smart, Playful',
//         favFood: 'Chicken, Carrots, Peanut Butter',
//         favActivity: 'Agility training, Running',
//         fear: 'Thunder',
//         avatar: '', // No avatar provided
//         sex: "Male",
//         type: "Dog",
//         petID: {
//             chipNumber: '444555666',
//             animalID: '444555666',
//             spayed: false,
//             vaccines: true,
//         },
//     },
//     8: {
//         id: 8,
//         name: 'Luna',
//         breed: 'Sphynx',
//         age: 2,
//         weight: 8,
//         personality: 'Curious, Affectionate',
//         favActivity: 'Climbing, Chasing toys',
//         avatar: 'https://example.com/images/sphynx.jpg',
//         sex: "Female",
//         type: "Cat",
//     },
//     9: {
//         id: 9,
//         name: 'Oscar',
//         breed: 'Beagle',
//         age: 5,
//         weight: 30,
//         livesIn: 'San Francisco, CA',
//         personality: 'Curious, Friendly, Vocal',
//         favFood: 'Turkey, Apples, Carrots',
//         favActivity: 'Sniffing around, Digging',
//         avatar: 'https://images.dog.ceo/breeds/beagle/n02088364_11134.jpg',
//         sex: "Male",
//         type: "Dog",
//         petID: {
//             chipNumber: '777888999',
//             animalID: '777888999',
//             spayed: false,
//             vaccines: true
//         },
//         parents: [7],
//     },
//     10: {
//         id: 10,
//         name: 'Snowball',
//         breed: 'Angora',
//         age: 4,
//         weight: 12,
//         livesIn: 'Miami, FL',
//         personality: 'Calm, Gentle',
//         favFood: 'Fish, Catnip',
//         avatar: 'https://example.com/images/angora.jpg',
//         sex: "Male",
//         type: "Cat",
//         petID: {
//             chipNumber: '555555555',
//             animalID: '555555555',
//             spayed: true,
//             vaccines: false,
//         },
//     },
// };
export const pets = {
    1: {
        id: 1,
        name: 'Buddy',
        pet_type: 'dog',
        breed: 'Golden Retriever',
        date_of_birth: '2019-01-01',
        sex: 'male',
        is_microchipped: true,
        is_neutered: true,
        is_vaccinated: true,
        photo: 'https://images.dog.ceo/breeds/segugio-italian/n02090722_002.jpg',
        address: 'New York, NY',
        extra_data: {
            personality: 'Friendly, Energetic, Playful',
            favFood: 'Chicken, Carrots, Apples',
            favActivity: 'Playing fetch, Going on long walks',
            fear: 'Thunder, Fireworks',
            weight: 65,
            parents: [1, 2],
            microchip_id: '123456789',
            animal_id: '123456789',
        },
        social_links: {},
        created_at: '2022-06-04T12:00:00Z',
    },
    2: {
        id: 2,
        name: 'Whiskers',
        pet_type: 'cat',
        breed: 'Siamese',
        date_of_birth: '2022-01-01',
        sex: 'female',
        is_microchipped: true,
        is_neutered: true,
        is_vaccinated: true,
        photo: 'https://i.pinimg.com/736x/0c/82/fb/0c82fb72d66bd68a7175ac12a95267fc.jpg',
        address: 'New York, NY',
        extra_data: {
            personality: 'Independent, Curious, Playful',
            favFood: 'Fish, Chicken, Catnip',
            favActivity: 'Exploring, Napping',
            fear: 'Loud noises, Strangers',
            weight: 10,
            parents: [1, 2],
            microchip_id: '123456789',
            animal_id: '123456789',
        },
        social_links: {
            instagram: 'https://www.instagram.com/whiskers',
            facebook: 'https://www.facebook.com/whiskers',
            twitter: 'https://www.twitter.com/whiskers'
        },
        created_at: '2022-06-04T12:00:00Z',
    },
    3: {
        id: 3,
        name: 'Fluffy',
        pet_type: 'cat',
        breed: 'Persian',
        date_of_birth: '2021-01-01',
        sex: 'female',
        is_microchipped: true,
        is_neutered: true,
        is_vaccinated: false,
        photo: null,
        address: 'New York, NY',
        extra_data: {
            personality: 'Calm, Affectionate, Playful',
            favFood: 'Chicken, Salmon, Catnip',
            favActivity: 'Napping, Grooming',
            fear: 'Loud noises, Strangers',
            weight: 15,
            parents: [3],
            microchip_id: '987654321',
            animal_id: '987654321',
        },
        social_links: null,
        created_at: '2022-06-04T12:00:00Z',
    },
    4: {
        id: 4,
        name: 'Mittens',
        pet_type: 'cat',
        breed: 'Maine Coon',
        date_of_birth: '',
        sex: 'female',
        is_microchipped: false,
        is_neutered: false,
        is_vaccinated: false,
        photo: 'https://example.com/images/maine-coon.jpg',
        address: null,
        extra_data: {
            microchip_id: null,
            animal_id: null,
        },
        social_links: null,
    },
    5: {
        id: 5,
        name: 'Rex',
        pet_type: 'dog',
        breed: 'German Shepherd',
        date_of_birth: '2018-01-01',
        sex: 'male',
        is_microchipped: true,
        is_neutered: false,
        is_vaccinated: true,
        photo: 'https://images.dog.ceo/breeds/germanshepherd/n02106662_5170.jpg',
        address: 'Los Angeles, CA',
        extra_data: {
            personality: 'Loyal, Intelligent, Protective',
            favFood: 'Beef, Chicken, Bones',
            favActivity: 'Guarding, Running',
            fear: 'Water',
            weight: 70,
            parents: [5, 6],
            microchip_id: '555666777',
            animal_id: '555666777',
        },
        social_links: null
    },
    6: {
        id: 6,
        name: 'Bella',
        pet_type: 'dog',
        breed: 'Labrador Retriever',
        date_of_birth: '2020-01-01',
        sex: 'female',
        is_microchipped: true,
        is_neutered: true,
        is_vaccinated: true,
        photo: 'https://images.dog.ceo/breeds/labrador/n02099712_757.jpg',
        address: 'Seattle, WA',
        extra_data: {
            personality: 'Playful, Friendly, Gentle',
            favFood: 'Fish, Chicken, Peanuts',
            favActivity: 'Swimming, Fetch',
            weight: 55,
            microchip_id: '999888777',
            animal_id: '999888777',
        },
        social_links: null
    },
    7: {
        id: 7,
        name: 'Coco',
        pet_type: 'dog',
        breed: 'Poodle',
        date_of_birth: '2017-01-01',
        sex: 'male',
        is_microchipped: true,
        is_neutered: false,
        is_vaccinated: true,
        photo: null,
        address: 'Austin, TX',
        extra_data: {
            personality: 'Energetic, Smart, Playful',
            favFood: 'Chicken, Carrots, Peanut Butter',
            favActivity: 'Agility training, Running',
            fear: 'Thunder',
            weight: 20,
            microchip_id: '444555666',
            animal_id: '444555666',
        },
        social_links: null
    },
    8: {
        id: 8,
        name: 'Luna',
        pet_type: 'cat',
        breed: 'Sphynx',
        date_of_birth: '2022-01-01',
        sex: 'female',
        is_microchipped: false,
        is_neutered: false,
        is_vaccinated: false,
        photo: 'https://example.com/images/sphynx.jpg',
        address: null,
        extra_data: {
            personality: 'Curious, Affectionate',
            favActivity: 'Climbing, Chasing toys',
            weight: 8,
            microchip_id: null,
            animal_id: null,
        },
        social_links: null
    },
    9: {
        id: 9,
        name: 'Oscar',
        pet_type: 'dog',
        breed: 'Beagle',
        date_of_birth: '2019-01-01',
        sex: 'male',
        is_microchipped: true,
        is_neutered: false,
        is_vaccinated: true,
        photo: 'https://images.dog.ceo/breeds/beagle/n02088364_11134.jpg',
        address: 'San Francisco, CA',
        extra_data: {
            personality: 'Curious, Friendly, Vocal',
            favFood: 'Turkey, Apples, Carrots',
            favActivity: 'Sniffing around, Digging',
            weight: 30,
            parents: [7],
            microchip_id: '777888999',
            animal_id: '777888999',
        },
        social_links: null
    },
    10: {
        id: 10,
        name: 'Snowball',
        pet_type: 'cat',
        breed: 'Angora',
        date_of_birth: '2024-01-01',
        sex: 'male',
        is_microchipped: true,
        is_neutered: true,
        is_vaccinated: false,
        photo: 'https://example.com/images/angora.jpg',
        address: 'Miami, FL',
        extra_data: {
            personality: 'Calm, Gentle',
            favFood: 'Fish, Catnip',
            weight: 12,
            microchip_id: '555555555',
            animal_id: '555555555',
        },
        social_links: null
    }
};

export const parents = {

    1: {

        id: 1,
        name: 'John Doe',
        email: 'johnDoe@mail.com',
        phone: '123-456-7890',
        address: '123 Main St, New York, NY 10001',
        passport_number: '123456789',
        date_of_birth: '1980-01-01',
        id_number: '1234567890',
    },
    2: {
        id: 2,
        name: 'Jane Doe',
        email: 'JaneDoe@mail.com',
        phone: '123-456-7890',
        address: '123 Main St, New York, NY 10001',
        passport_number: '987654321',
        date_of_birth: '1980-01-01',
        id_number: '0987654321',
    }
};

// TODO TBD
export const appointments = [
    {
        id: 1,
        petID: 1,
        parentID: 1,
        vetID: 1,
        dateTime: '2024-06-04, 1:00 PM',
        duration: '1 hour',
        reason: 'Checkup',
        status: 'upcoming',
        notes: 'Buddy is due for his annual checkup.',
        vetNotes: 'Buddy is in good health. No major concerns, but we will keep an eye on his diet.',
    }
]