import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.jsx';
import EditableText from '../EditableText/EditableText.jsx';
import ReusableMenu from '../Menus/ReusableThreeMenuSession.jsx';
import { Link } from 'react-router-dom';
import { paths } from '../../data/constants.js';

const useStyles = makeStyles({
  title: {
    '&.MuiTypography-root': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '19.5px',
      color: 'var(--Faux-Black, rgba(31, 31, 42, 1))',
      marginBottom: '8px',
    }
  },
  sessionsItemContainer: {
    width: "100%",
    height: 'fit-content',
    borderRadius: '8px',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 15px 0px #0000000D',
    backgroundColor: 'var(--White, #FFFFFF)',
    marginBottom: '12px',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      boxShadow: '0px 4px 16.8px 0px #0000001A',
      cursor: 'pointer',
    }
  },
  sessionsDate: {
    '&.MuiTypography-root': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '19.5px',
      color: '#7D7D97',
    }
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '19.5px',
      color: 'var(--Faux-Black, rgba(31, 31, 42, 1))',
      '&:hover': {
        backgroundColor: 'var(--Light-Purple, #F8F7FF)',
      }
    }
  }
});

const SessionItem = ({ session, handleSessionDelete, handleSessionUpdate }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    handleSessionDelete();
    handleCloseDialog();
  };

  const handleRename = () => {
    setIsEditing(() => true);
  };

  const handleSave = (newTitle) => {
    handleSessionUpdate(session.id, newTitle);
    setIsEditing(false);
  };
  const menuItems = [
    {
      label: 'Rename Session',
      icon: <BorderColorOutlinedIcon />,
      action: handleRename
    },
    {
      label: 'Delete Session',
      icon: <DeleteOutlinedIcon />,
      action: handleOpenDialog
    },
    {
      label: 'Report Session',
      icon: <FlagOutlinedIcon />,
      action: () => console.log('Report action')
    }
  ];

  return (
    <Grid container className={classes.sessionsItemContainer} alignItems="center" justifyContent="space-between">
      <Link to={paths.session(session.id)} style={{ textDecoration: 'none', flex: "auto" }}>
        <Grid item>
          <EditableText
            text={session.title}
            onSave={handleSave}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            className={classes.title}
          />
          <Typography className={classes.sessionsDate}>
            {session.dateTime}
          </Typography>
        </Grid>
      </Link>
      <Grid item>
        <ReusableMenu session={session} menuItems={menuItems} />
      </Grid>
      <ConfirmationDialog
        open={dialogOpen}
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmDelete}
        title={`Delete "${session.title}"?`}
        content="You will not be able to retrieve this session once it has been deleted."
        confirmLabel="Delete Session"
      />
    </Grid>
  );
};

export default SessionItem;
