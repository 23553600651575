import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Select, MenuItem, FormControl, Chip } from '@mui/material';
import { useState, useEffect } from 'react';
import ReusableMenu from '../Menus/ReusableThreeMenuSession.jsx';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getSummary } from '../../utils/summaryGenerator.js';
import { toast } from 'react-toastify';
import SummaryTextFieldV2 from '../EditableText/SummaryTextFieldV2.jsx';
import CustomTreetButtonHollow from '../Buttons/CustomTreetButtonHollow.jsx';
import AwesomeLoader from '../Loaders/AwesomeLoader.jsx';

const useStyles = makeStyles((theme) => ({
    summaryContainer: {
        width: '100%',
        height: '100%',
    },
    summaryBody: {
        padding: '16px',
        overflowY: 'auto',
        // width: '100%',
        backgroundColor: 'var(--White, #FFFFFF)',
        border: '1px solid var(--Light-Purple, #E3E8FF)',
        // all children with tag textarea

        height: '100%',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '80px',
        },
    },
    summary: {
        '&.MuiTypography-root': {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '19.5px',
        },
    },
    emptySummary: {
        // center the text in the container
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    summaryHeight: {
        height: 'calc(100dvh - 250px)',
    },
    summarySelect: {
        '& .MuiSelect-select': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '19.5px',
            color: 'var(--Faux-Black, rgba(31, 31, 42, 1))',
        },
    },
    summarySelectWrapper: {
        '&.MuiFormControl-root': {
            backgroundColor: 'var(--White, #FFFFFF)',
            borderRadius: '8px',
            border: '1px solid var(--Light-Purple, #E3E8FF)',
            maxHeight: '50px',
        },
    },
    summarySelectItem: {
        '&.MuiMenuItem-root': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '19.5px',
            color: 'var(--Faux-Black, rgba(31, 31, 42, 1))',
        },
    },
    GenButtonWrapper: {
        // center the button at the bottom of the screen
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            position: 'fixed',
            bottom: '0dvh',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            backgroundColor: 'var(--White, #FFFFFF)',
            padding: '16px 24px',
            width: "100%",
            justifyContent: "center",
            alignItems: 'center',
            borderTop: '1px solid var(--Mid-Blue, #E3E8FF)'
        },
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100dvh - 250px)',
    },
}));

const Summary = ({ session, width, isSmallScreen, handleSessionUpdate, setSummaryProcessing }) => {
    const classes = useStyles();
    const [summary, setSummary] = useState(null);
    const [summaryType, setSummaryType] = useState('Simple');
    const [isEditing, setIsEditing] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false); // Loader state
    const [contentSource, setContentSource] = useState('');

    const CHAR_MIN_LIMIT = 100;

    useEffect(() => {
        if (!session?.summary) {
            setSummary(null);
            setContentSource('localStorage');
            return;
        }

        // Check if the content is already fetched from API
        if (contentSource !== 'api') {
            const currentSummary = session.summary[summaryType];
            if (currentSummary) {
                setSummary(currentSummary);
                setContentSource('localStorage');  // Indicate content is from localStorage/session
            } else {
                setSummary('');  // If there's no summary for the type, set it to empty
            }
        }
    }, [session.summary, summaryType]);  // Trigger effect on session.summary or summaryType change


    const handleSave = (stringJsonContent, sessionTitle) => {
        const summaryToUpdate = {
            Simple: summaryType === 'Simple' ? stringJsonContent : session.summary.Simple,
            Detailed: summaryType === 'Detailed' ? stringJsonContent : session.summary.Detailed,
        }
        handleSessionUpdate(session.id, { summary: summaryToUpdate });
    };

    const handleSummaryTypeChange = (type) => {
        console.log("summaryType:", type);
        setSummaryType(type);
        if (session?.summary) {
            const currentSummary = session.summary[type];
            if (currentSummary) {
                setSummary(currentSummary);
                setContentSource('localStorage');
            } else {
                setSummary('');  // If there's no summary for the type, set it to empty
            }
        }
    };

    const fetchSummary = async () => {
        try {
            setIsProcessing(true);
            setSummaryProcessing(true);
            const data = await getSummary(session?.transcription, summaryType);

            if (!data) {
                toast.error('Failed to generate summary');
                return;
            }
            console.log("data:", data);
            const parsedData = JSON.parse(data);
            if (!parsedData) {
                toast.error('Failed to parse summary data');
                return;
            }
            if (session?.title === 'Untitled'){
                handleSessionUpdate(session.id, { title: parsedData.sessionTitle  });
            }
            setContentSource('api');
            setSummary(data);
        } catch (error) {
            console.error('Error fetching summary:', error);
        } finally {
            setIsProcessing(false);
            setSummaryProcessing(false);
        }
    };

    const handleGenerateSummary = () => {
        if (session?.transcription && session?.transcription.length > CHAR_MIN_LIMIT) {
            console.log("Generating summary...");
            fetchSummary();
        } else {
            toast.warn('Transcription is too short!');
        }
    };
    const handleCopy = () => {
        const summaryToCopy = summary;
        if (summaryToCopy) {
            navigator.clipboard.writeText(summaryToCopy);
        } else {
            toast.warn('No summary available to copy');
        }
    };



    const menuItems = [
        {
            label: 'Copy summary',
            icon: <ContentCopyRoundedIcon />,
            action: () => handleCopy(),
            disable: !summary,
        },
        {
            label: 'Edit summary',
            icon: <BorderColorOutlinedIcon />,
            action: () => setIsEditing(true),
            disable: !summary,
        },
        {
            label: 'Share summary',
            icon: <IosShareOutlinedIcon />,
            action: () => console.log('share action'),
            disable: !summary,
        },
    ];

    return (
        <Grid container className={classes.summaryContainer} flexDirection='column' wrap='nowrap' width={width}>
            <Grid container minHeight={80} padding={"16px 0px"} flexDirection='row' alignItems='center' justifyContent={'space-between'}>
                <Grid item>
                    <Typography className={classes.summary}>
                        Summary
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container>
                        <div className={classes.GenButtonWrapper}>
                            <CustomTreetButtonHollow icon="AutoAwesome" onClick={handleGenerateSummary}>Generate</CustomTreetButtonHollow>
                        </div>
                        <ReusableMenu session={session} menuItems={menuItems} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={1} style={{ marginBottom: '16px' }}>
                <Grid item>
                    <Typography style={{ fontWeight: 500, fontSize: '16px' }}>Type</Typography>
                </Grid>
                <Grid item>
                    <Chip label="Simple" color={summaryType === 'Simple' ? 'primary' : 'default'} onClick={() => handleSummaryTypeChange('Simple')} style={{ marginRight: '8px' }} />
                    <Chip label="Detailed" color={summaryType === 'Detailed' ? 'primary' : 'default'} onClick={() => handleSummaryTypeChange('Detailed')} style={{ marginRight: '8px' }} />
                </Grid>
            </Grid>

            {isProcessing ? (
                <div className={classes.loaderContainer}>
                    <AwesomeLoader />
                    <Typography ml={2}>Processing summary, please wait...</Typography>
                </div>
            ) : (
                summaryType && summary ? (
                    <Grid item >
                        <SummaryTextFieldV2
                            initialJsonText={summary}
                            handleSave={handleSave}
                            contentSource={contentSource}
                            setContentSource={setContentSource}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            clickable={!isSmallScreen}
                        />
                    </Grid>
                ) : (
                    <Grid item className={classes.emptySummary + ' ' + classes.summaryHeight}>
                        <Typography variant='body1' style={{
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '30px',
                            textAlign: 'center',
                            color: '#7D7D97',
                            maxWidth: '250px',
                        }}>
                            A summary of your transcript will appear here
                        </Typography>
                    </Grid>
                )
            )}
        </Grid>
    );
};


export default Summary;