import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import Header from '../components/Header/header.jsx';
import DefaultHeader from '../components/Header/defaultHeader.jsx';
import LoginOrSignupForm from '../components/Auth/LoginOrSignupForm.jsx';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    title: {
        '&.MuiTypography-root': {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24.38px',
            textAlign: 'left',
            color: theme.palette.text.primary,
        },
    },
    petsTitleContainer: {
        marginTop: '24px',
        marginBottom: '16px',
    },
    petsValue: {
        color: '#7019FF',
        paddingLeft: '12px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    navContainer: {
        backgroundColor: '#f8f9fa',
        boxShadow: '0px 4px 15px 0px #0000000D',
        borderBottom: '1px solid rgba(227, 232, 255, 1)',
        width: '100%',
        minHeight: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemsContainer: {
        // maxWidth: '900px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const handleSavePassword = () => {
    // get from localStorage
    const auth = localStorage.getItem('auth');
    if (!auth) {
        toast.error('Invalid request');
        return;
    }

    // TODO: call save password API

    // clear localStorage
    localStorage.removeItem('auth');
}

const Login = () => {
    const classes = useStyles();

    useEffect(() => {
        localStorage.removeItem('auth');
    }, []);

    return (
        <>
            <Header navContainer={classes.navContainer} itemsContainer={classes.itemsContainer}>
                <DefaultHeader />
            </Header>
            <Container maxWidth="lg">
                <div className={classes.contentContainer}>
                    <LoginOrSignupForm />
                </div>
            </Container>
        </>
    );
};

export default Login;
