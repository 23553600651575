import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { GoogleLogin } from '@react-oauth/google';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '16px',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '8px',
        gap: '8px',
    },
    iconText: {
        fontSize: '14px',
        fontWeight: 650,
        color: "#1F1F2A",
        lineHeight: '21.7px',
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    sectionTitle: {
        paddingTop: '40%',
        paddingBottom: '5%',
    },
    inputTextBox: {
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
    },
    textIdFields: {
        textAlign: 'left',
        color: '#7D7D97',
        fontSize: 12,
        lineHeight: '18.6px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    detailSection: {
        marginBottom: '24px',
    },
    googleButton: {
        width: '100%',
        padding: '16px',
        borderRadius: '8px',
        fontSize: '16px',
        lineHeight: '24.8px',
        fontWeight: 600,
    },
}));

const LoginOrSignupForm = () => {
    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (email !== '' && email.includes('@') && email.includes('.')) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [email]);
    
    return (
        <>
            <div className={classes.detailSection}>

                {/* main title */}
                <Typography variant="h4" component="div" className={classes.sectionTitle}>
                    <span style={{fontWeight: 600}}>Login or signup</span>
                </Typography>

                {/* email field */}
                <TextField
                    align="left"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className={classes.inputTextBox}
                    placeholder="Enter your email address"
                    sx={{
                        cursor: 'text',// : 'pointer',
                        color: '#1F1F2A',// : '#7D7D97',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        fontWeight: 500,
                    }}
                    slotProps={{
                        inputLabel: {
                            shrink: false,
                        }
                    }}
                />

                {/* next button */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={undefined}
                    sx={{
                        width: '100%',
                        padding: '16px',
                        borderRadius: '8px',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        fontWeight: 600,
                        marginTop: '16px',
                    }}
                    disabled={disabled}
                >
                    Next
                </Button>

                <Divider textAlign="center" style={{
                    paddingTop: '30px', 
                    paddingBottom: '30px', 
                }}>
                    <Typography variant="body1" color="textSecondary">
                        OR
                    </Typography>
                </Divider>

                {/* social login button */}
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.socialButton}
                    onClick={undefined}
                    sx={{
                        width: '100%',
                        padding: '16px',
                        borderRadius: '8px',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        fontWeight: 600,
                        color: '#000',
                        textTransform: 'none',
                    }}
                    >
                        <img src={`${process.env.PUBLIC_URL}/authIcons/google.svg`} alt="google icon" className={classes.icon} />
                    Login with Google
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.socialButton}
                    onClick={undefined}
                    sx={{
                        width: '100%',
                        padding: '16px',
                        borderRadius: '8px',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        marginTop: '24px',
                        fontWeight: 600,
                        color: '#000',
                        textTransform: 'none',
                    }}
                    >
                        <img src={`${process.env.PUBLIC_URL}/authIcons/facebook.svg`} alt="facebook icon" className={classes.icon} />
                    Login with Facebook
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.socialButton}
                    onClick={undefined}
                    sx={{
                        width: '100%',
                        padding: '16px',
                        borderRadius: '8px',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        marginTop: '24px',
                        fontWeight: 600,
                        color: '#000',
                        textTransform: 'none',
                    }}
                    >
                        <img src={`${process.env.PUBLIC_URL}/authIcons/apple.svg`} alt="apple icon" className={classes.icon} />
                    Login with Apple
                </Button>
            </div>
        </>
    );
};

export default LoginOrSignupForm;
