import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Button, Typography, Box } from '@mui/material';

const MessageDrawer = ({ open, onClose, imageSrc, text, subText = null }) => {

    return (
        // add effect when drawer is opening
        <Drawer anchor="bottom" open={open} onClose={onClose} 
            sx={{
                '& .MuiDrawer-paper': {
                    borderRadius: '16px 16px 0px 0px',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px -4px 8px 0px #1E07511F',
                    maxWidth: '600px',
                    // center the drawer
                    margin: 'auto',
                    // remove the scrollbar
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },

                },

            }}
        >
            <Box
                sx={{
                    maxWidth: 600,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    paddingBottom: '85px',

                }}
            >
                <img src={imageSrc} alt="Drawer Image" style={{ width: '100%', height: '60%' }} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        padding: '24px 20px',
                        height: '40%',
                        maxWidth: '600px',
                        width: '100%',
                        gap: '16px',
                    }}
                >

                    <Typography sx={{
                        fontSize: '24px',
                        fontWeight: 600,
                        lineHeight: '33.6px',
                        textAlign: 'left',
                    }}>
                        {text}
                    </Typography>
                    {subText &&
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '24.8px',
                                color: '#7D7D97',
                                textAlign: 'left',
                            }}
                        >
                            {subText}
                        </Typography>
                    }
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        position: 'fixed',
                        bottom: 0,
                        maxWidth: '600px',
                        padding: '0px 20px 24px 20px',
                        borderRadius: '8px',
                        boxShadow: '0px 2px 8px 0px #1E07511F',
                        width: '100%',
                    }}
                >
                    <Button
                        sx={{
                            width: '100%',
                            height: '48px',
                            fontSize: '16px',
                            lineHeight: '19.5px',
                            fontWeight: 600,
                        }}
                        variant="contained" fullWidth color="primary" onClick={onClose}>
                        Got it!
                    </Button>
                </Box>
            </Box>
        </Drawer>

    );
};

MessageDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    imageSrc: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default MessageDrawer;