import React, { useState, useEffect, useRef } from 'react';
import { TextField, Typography } from '@mui/material';

const EditableText = ({
    text,
    onSave,
    isEditing,
    setIsEditing,
    className,
    clickable = false,
    multiline = false,
    width = 'auto',
    placeholder = "",
    isTitle = true,
    shouldValidate = true
}) => {
    const [value, setValue] = useState(text);
    const [error, setError] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        setValue(text);
    }, [text]);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
            if (!multiline) inputRef.current.select();
        }
    }, [isEditing]);

    const validate = (val) => {
        if (!shouldValidate) return true;
        const isValid = val?.trim() !== '';
        setError(!isValid);
        return isValid;
    };

    const handleSave = () => {
        if (validate(value)) {
            onSave(value?.trim());
            setIsEditing(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !multiline) {
            handleSave();
        } else if (e.key === 'Escape') {
            setValue(text);
            setError(false);
            setIsEditing(false);
        }
    };

    const handleBlur = handleSave;

    const handleClick = (e) => {
        if (clickable) {
            setIsEditing(true);
        }
    };
    const handleMouseDown = (e) => {
        e.preventDefault();
      };

    const sharedProps = {
        value,
        onChange: (e) => {
            setValue(e.target.value);
            validate(e.target.value);
        },
        onKeyDown: handleKeyDown,
        onBlur: handleBlur,
        inputRef,
        error,
        helperText: error ? "Text cannot be empty" : "",
        multiline,
        rows: multiline ? 'auto' : 1,
        style: {
            height: multiline ? '100%' : 'auto',
            width: width ?? 'auto',
        },
        InputProps: {
            className,
            style: {
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '24.38px',
                border: 'none',
                borderRadius: multiline ? '12px' : 'auto',
            },
            placeholder,
        },
        fullWidth: multiline,
        onMouseDown: (!clickable && !isEditing) ? handleMouseDown : undefined,
    };

    return isEditing || !isTitle ? (
        <TextField {...sharedProps} onClick={isTitle ? undefined : handleClick} />
    ) : (
        <Typography className={className} onClick={handleClick}>{text}</Typography>
    );
};

export default EditableText;
