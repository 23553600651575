import React, { useEffect, useState } from 'react';
import SessionHeader from '../components/Header/sessionHeader.jsx';
import { useTheme, useMediaQuery, Container } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import sessionsDemo from '../data/sessions.js';
import Header from '../components/Header/header.jsx';
import TabPanel from '../components/Tabs/TabPanel.jsx';
import { makeStyles } from '@mui/styles';
import Transcription from '../components/Transcript/transcription.jsx';
import Summary from '../components/Summary/summary.jsx';
import useSessionStorage from '../managers/sessionsManager.jsx';
import { paths } from '../data/constants.js';

const useStyles = makeStyles((theme) => ({
  Container: {
    '&.MuiContainer-root': {
      backgroundColor: theme.palette.background.notes,
    },
  },
  transcriptionContainer: {
    '&.MuiContainer-root': {
      display: 'flex',
      flexDirection: 'row',
      height: 'calc(100vh - 64px)',
      marginRight: '0px',
      marginLeft: '0px',
      maxWidth: '100%',
    },
  },
  summaryContainer: {
    flex: 1,
    height: '100%',
  },
  divideLine: {
    width: '1px',
    height: '100%',
    backgroundColor: '#E3E8FF',
    margin: '0px 40px'
  },
  tabPanelContainer: {
    background: theme.palette.background.notes,  
  },
}));

const SessionPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const { sessionId } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [sessions, updateSession] = useSessionStorage('sessions', sessionsDemo);
  const [summaryProcessing, setSummaryProcessing] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const session = sessions[sessionId];

  useEffect(() => {
    if (!session) {
      navigate(paths.notes); // Redirect to home if the session does not exist
    }
  }, [session, navigate]);

  const handleSessionUpdate = (sessionId, updatedFields) => {
    updateSession(sessionId, updatedFields);
  };

  return (
    <div>
      <Header>
        <SessionHeader session={session} handleSessionUpdate={handleSessionUpdate} setTabIndex={setTabIndex} tabIndex={tabIndex} isSmallScreen={isSmallScreen} />
      </Header>
      {isSmallScreen ?
        <>
          <TabPanel value={tabIndex} index={0} className={classes.tabPanelContainer}>
            {/* render transcription */}
            <Transcription readOnly={summaryProcessing} session={session} width='100%' isSmallScreen={isSmallScreen} handleSessionUpdate={handleSessionUpdate} />
          </TabPanel>
          <TabPanel value={tabIndex} index={1} className={classes.tabPanelContainer}>
            {/* render summary */}
            <Summary setSummaryProcessing={setSummaryProcessing} session={session} width="100%" isSmallScreen={isSmallScreen} handleSessionUpdate={handleSessionUpdate}/>
          </TabPanel>
        </>
        :
        // Desktop view
        <Container className={`${classes.transcriptionContainer} ${classes.Container}`}>
          <Transcription readOnly={summaryProcessing} session={session} width="65%" isSmallScreen={isSmallScreen} handleSessionUpdate={handleSessionUpdate} />
          <div className={classes.divideLine}></div>
          <Summary setSummaryProcessing={setSummaryProcessing} session={session} width="35%" isSmallScreen={isSmallScreen} handleSessionUpdate={handleSessionUpdate}/>
        </Container>
      }
    </div>
  );
};

export default SessionPage;
