import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Button, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    drawerPaper: {
        borderRadius: '8px 8px 0 0',
    },
    button: {
        "&.MuiButton-root": {
            fontWeight: 600,
            padding: '0px, 16px, 0px, 16px',
            border: '1px solid #E3E8FF',
            boxShadow: 'none',
            "&:hover": {
                boxShadow: 'none',
            }
        },
    },
});


const ConfirmationDialog = ({
    open,
    handleClose,
    handleConfirm,
    title = "Confirm Action",
    content = "Are you sure you want to proceed?",
    confirmLabel = "Confirm"
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const renderContent = () => (
        <>
            <DialogTitle style={{ fontWeight: 600 }}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ fontWeight: 500 }}>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.button} onClick={handleClose} variant="contained" color="secondary">
                    Cancel
                </Button>
                <Button className={classes.button} onClick={handleConfirm} variant="contained" color="primary" autoFocus>
                    {confirmLabel}
                </Button>
            </DialogActions>
        </>
    );

    return isMobile ? (
        <Drawer classes={{ paper: classes.drawerPaper }} anchor="bottom" open={open} onClose={handleClose}>
            {renderContent()}
        </Drawer>
    ) : (
        <Dialog open={open} onClose={handleClose}>
            {renderContent()}
        </Dialog>
    );
};

export default ConfirmationDialog;
