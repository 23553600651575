import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PetProfileCarousel from '../Carousel/Carousel';
import { makeStyles } from '@mui/styles';
import { paths } from '../../data/constants.js';
import { Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import Box from '@mui/material/Box';
const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '16px',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '8px',
        gap: '8px',
    },
    iconText: {
        fontSize: '14px',
        fontWeight: 600,
        color: "#1F1F2A",
        lineHeight: '21.7px',
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    inputTextBox: {
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
    },
    textIdFields: {
        textAlign: 'left',
        color: '#7D7D97',
        fontSize: 12,
        lineHeight: '18.6px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    chip: {
        height: '24px',
        borderRadius: '32px',
        fontSize: '12px',
        lineHeight: '18.6px',
        fontWeight: 600,
        background: 'var(--Light-Grey, #F6F7FB) !important',
        width: 'fit-content',
    },
    detailSection: {
        marginBottom: '24px',
    },
    chipLabel: {
        "&.MuiChip-label": {
            padding: '8px',
        },
    },
}));

// Define the hook for dynamic styling
const useMicrochipStyle = (value) => useMemo(() => ({
    color: value ? '#1F1F2A' : '#7D7D97',
    fontSize: '16px',
    lineHeight: '24.8px',
    fontWeight: 500,
}), [value]);

// Separate component for styled microchip information
const MicrochipTypography = ({ label, value, customStyle }) => {
    const style = useMicrochipStyle(value);
    const classes = useStyles();

    return (
        <div style={customStyle}>
            <Typography align="left" className={classes.textIdFields}>
                {label.toUpperCase()}
            </Typography>
            <Typography align="left" sx={style}>
                {value || '-'}
            </Typography>
        </div>
    );
};

const ClickableTextField = ({ pet, text, placeholder, classes }) => {
    const navigate = useNavigate();

    const onClick = () => {
        if (text) return;
        navigate(paths.petCreateEdit, { state: { petID: pet?.id } });
    };

    return (
        <Typography
            onClick={onClick}
            sx={{
                cursor: text ? 'text' : 'pointer',
                color: text ? '#1F1F2A' : '#7D7D97',
                fontSize: '16px',
                lineHeight: '24.8px',
                fontWeight: 500,
            }}
            InputProps={{ readOnly: true }}
            align="left"
            className={classes.inputTextBox}
        >
            {text || placeholder}
        </Typography>
    );
};

const MedicalInfoChips = ({ pet }) => {
    const classes = useStyles();
    const isMicrochipped = pet?.is_microchipped;
    const isVaccinated = pet?.is_vaccinated;
    const isNeutered = pet?.is_neutered;
    if (!isMicrochipped && !isVaccinated && !isNeutered) return null;
    return (
        <Grid container spacing={1} flexWrap={'nowrap'} sx={{
            overflowX: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
            }
        }}>
            {isMicrochipped && (
                <Grid item>
                    <Chip
                        icon={<DoneRoundedIcon sx={{
                            color: '#1F1F2A',
                            fontSize: '16px',
                            lineHeight: '24.8px',
                            fontWeight: 500,
                        }} />}
                        label="Microchipped"
                        variant="filled"
                        className={classes.chip}
                    />
                </Grid>
            )}
            {isVaccinated && (
                <Grid item>
                    <Chip
                        icon={<DoneRoundedIcon sx={{
                            color: '#1F1F2A',
                            fontSize: '16px',
                            lineHeight: '24.8px',
                            fontWeight: 500,
                        }} />}
                        label="Vaccinated"
                        variant="filled"
                        className={classes.chip}
                    />
                </Grid>
            )}
            {isNeutered && (
                <Grid item>
                    <Chip
                        icon={<DoneRoundedIcon sx={{
                            color: '#1F1F2A',
                            fontSize: '16px',
                            lineHeight: '24.8px',
                            fontWeight: 500,
                        }} />}
                        label="Neutered"
                        variant="filled"
                        className={classes.chip}
                    />
                </Grid>
            )}
        </Grid>
    );
};

const PetGeneralDetails = ({ pet }) => {
    const classes = useStyles();

    return (
        <>
            <MedicalInfoChips pet={pet} />
            <PetProfileCarousel pet={pet} />
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <img src={`${process.env.PUBLIC_URL}/profileIcons/psychology.svg`} alt="brain" />
                    <Typography align="left" className={classes.iconText}>
                        Personality
                    </Typography>
                </div>
                <ClickableTextField pet={pet} text={pet?.extra_data?.personality} classes={classes} placeholder="Who am I?" />
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <img src={`${process.env.PUBLIC_URL}/profileIcons/favFood.svg`} alt="food" />
                    <Typography align="left" className={classes.iconText}>
                        Favourite food
                    </Typography>
                </div>
                <ClickableTextField pet={pet} text={pet?.extra_data?.favorite_food} classes={classes} placeholder="Taste buds loading..." />
            </div >
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <img src={`${process.env.PUBLIC_URL}/profileIcons/landscape.svg`} alt="activity" />
                    <Typography align="left" className={classes.iconText}>
                        Favourite activity
                    </Typography>
                </div>
                <ClickableTextField pet={pet} text={pet?.extra_data?.favorite_activity} classes={classes} placeholder="There’s so much to see and discover!" />
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <img src={`${process.env.PUBLIC_URL}/profileIcons/fear.svg`} alt="fear" />
                    <Typography align="left" className={classes.iconText}>
                        Biggest fear
                    </Typography>
                </div>
                <ClickableTextField pet={pet} text={pet?.extra_data?.biggest_fear} classes={classes} placeholder="Things that send shivers down my spine are..." />
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <img src={`${process.env.PUBLIC_URL}/profileIcons/fingerprint.svg`} alt="id" />
                    <Typography align="left" className={classes.iconText}>
                        ID
                    </Typography>
                </div>
                <div className={classes.inputTextBox}>
                    <MicrochipTypography customStyle={{ marginBottom: 16 }} label="Microchip Number" value={pet?.microchip_id} />
                    <MicrochipTypography label="Animal ID" value={pet?.animal_id} />
                </div>
            </div>
            {pet.social_links && Object.keys(pet.social_links).length > 0 && Object.values(pet.social_links).some(link => link !== "") && (
                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <img src={`${process.env.PUBLIC_URL}/profileIcons/social.svg`} alt="social" />
                        <Typography align="left" className={classes.iconText}>
                            Social Links
                        </Typography>
                    </div>
                    <Grid container spacing={1} flexWrap={'nowrap'} sx={{
                        overflowX: 'scroll',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        padding: '5px 0px',
                    }}>
                        {pet?.social_links && Object.entries(pet.social_links).map(([platform, url], index) => {
                            // Only render if url is valid
                            if (!url || !platform) return null;

                            const image = `${process.env.PUBLIC_URL}/socialIcons/${platform}.svg`;

                            return (
                                <Grid item key={index}>
                                    <Chip
                                        onClick={() => window.open(url, '_blank')}
                                        variant="filled"
                                        sx={{
                                            borderRadius: "8px",
                                            background: '#FFFFFFE5',
                                            boxShadow: '0px 2px 7.1px 0px #00000026',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        label={
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <img src={image} alt={platform} style={{
                                                    width: 24,
                                                    height: 24,
                                                }} />
                                            </Box>
                                        }
                                        classes={{ label: classes.chipLabel }}
                                    />
                                </Grid>
                            );
                        })}

                    </Grid>
                </div>
            )}
        </>
    );
};

export default PetGeneralDetails;
