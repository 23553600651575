import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Container, Typography, MenuItem, RadioGroup, FormControlLabel, Radio, Checkbox, FormGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DropzoneImage from '../DropImage/DropzoneImage.jsx';
import EditableText from '../EditableText/EditableText.jsx';
import usePetParentStorage from '../../managers/parentManager.jsx';
import { parents as parentsDemo } from '../../data/pets.js';
import IconButton from '@mui/material/IconButton';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
const useStyles = makeStyles((theme) => ({
    title: {
        '&.MuiTypography-root': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '19.5px',
            color: 'var(--Faux-Black, rgba(31, 31, 42, 1))',
            marginBottom: '8px',
        }
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        padding: '24px 0 100px 0px',
    },
    formItem: {
        marginBottom: '16px',
    },
    inputTextBox: {
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
    },
    detailSection: {
        marginBottom: '16px',
    },
    detailSectionTitle: {
        marginBottom: '24px',
    },
    titleSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },

    detailTitle: {
        "&.MuiTypography-root": {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.94px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '8px',
        gap: '8px',
    },
    iconText: {
        '&.MuiTypography-root': {
            fontSize: '14px',
            fontWeight: 600,
            color: "#1F1F2A",
            lineHeight: '21.7px',
        }
    },
    buttonGroup: {
        // make the button group sticky at the bottom
        display: 'flex',
        position: 'fixed',
        bottom: '0',
        height: 88,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        maxWidth: '600px', // Make it phone size only at 600px
        width: '100%',
        padding: "20px",
        background: "#fff",
        "& > button": {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.5px",
            textTransform: "none",
        },
    },
    editIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginBottom: '16px',
    },
}));

const AboutMeEditForm = () => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = React.useState(false);
    const handleEditClick = () => {
        setIsEditing(!isEditing);
    }
    const {
        storedPetParents,
        updatePetParent,
        addPetParent,
        deletePetParent,
        getPetParentById,
        getAllPetParents
    } = usePetParentStorage('parents', parentsDemo); // 'parents' key for localStorage
    const [initialValues, setInitialValues] = React.useState(getPetParentById(1));

    // current logged in user
    // TODO: replace with actual user
    // React.useEffect(() => {
    //     const user = getPetParentById(1);
    //     setInitialValues(user);
    // }, [storedPetParents]);

    const onSubmit = (values) => {
        if (values) {
            updatePetParent(initialValues.id, values);
        }
        setIsEditing(false);
       formik.setSubmitting(false);
    };
    const formik = useFormik({
        initialValues: {
            name: initialValues?.name || '',
            email: initialValues?.email || '',
            phone: initialValues?.phone || '',
            date_of_birth: initialValues?.date_of_birth || '',
            address: initialValues?.address || '',
            id_number: initialValues?.id_number || '',
            passport_number: initialValues?.passport_number || '',
            photo: initialValues?.photo || (process.env.PUBLIC_URL + '/logo-gray.svg'),
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            phone: Yup.string().required('Required').matches(/^[0-9]+$/, 'Must be a number'),
            date_of_birth: Yup.date().required('Required'),
            address: Yup.string().required('Required'),
            id_number: Yup.string().optional(),
            passport_number: Yup.string().optional(),
        }),
        onSubmit: onSubmit,
    });

    return (
        <Container maxWidth="sm">
            {!isEditing && (
                <div className={classes.editIcon}>
                    <IconButton className={classes.icons} onClick={handleEditClick}>
                        <BorderColorOutlinedIcon />
                    </IconButton>
                </div>
            )}
            <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
                <div className={classes.detailSection}>
                    <DropzoneImage formik={formik} isEditing={isEditing} />
                </div>
                <div className={`${classes.detailSection} ${classes.titleSection}`}>
                    <EditableText
                        text={formik.values.name}
                        onSave={(value) => formik.setFieldValue("name", value)}
                        isEditing={isEditing}
                        setIsEditing={() => {formik.dirty.valueOf("name"); setIsEditing(true)}}
                        name="name"
                        clickable={false}
                        multiline={false}
                        className={classes.title}
                    />
                </div>
                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <Typography align="left" className={classes.iconText}>
                            Email Address
                        </Typography>
                    </div>
                    <TextField
                        name="email"
                        type="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        className={classes.inputTextBox}
                        fullWidth
                        disabled={!isEditing}
                    />
                </div>
                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <Typography align="left" className={classes.iconText}>
                            Contact Number
                        </Typography>
                    </div>
                    <TextField
                        name="phone"
                        type="text"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                        className={classes.inputTextBox}
                        fullWidth
                        disabled={!isEditing}

                    />
                </div>
                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <Typography align="left" className={classes.iconText}>
                            Date of Birth
                        </Typography>
                    </div>
                    <TextField
                        name="date_of_birth"
                        type="date"
                        value={formik.values.date_of_birth}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                        helperText={formik.touched.date_of_birth && formik.errors.date_of_birth}
                        className={classes.inputTextBox}
                        fullWidth
                        disabled={!isEditing}
                        // minimum age is 10
                        InputProps={{ inputProps: { max: new Date(new Date().setFullYear(new Date().getFullYear() - 10)).toISOString().split('T')[0] } }}
                    />
                </div>
                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <Typography align="left" className={classes.iconText}>
                            Address
                        </Typography>
                    </div>
                    <TextField
                        name="address"
                        type="text"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                        className={classes.inputTextBox}
                        fullWidth
                        disabled={!isEditing}
                    />
                </div>
                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <Typography align="left" className={classes.iconText}>
                            ID Number
                        </Typography>
                    </div>
                    <TextField
                        name="id_number"
                        type="text"
                        value={formik.values.id_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.id_number && Boolean(formik.errors.id_number)}
                        helperText={formik.touched.id_number && formik.errors.id_number}
                        className={classes.inputTextBox}
                        fullWidth
                        disabled={!isEditing}
                    />
                </div>
                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <Typography align="left" className={classes.iconText}>
                            Passport Number
                        </Typography>
                    </div>
                    <TextField
                        name="passport_number"
                        type="text"
                        value={formik.values.passport_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.passport_number && Boolean(formik.errors.passport_number)}
                        helperText={formik.touched.passport_number && formik.errors.passport_number}
                        className={classes.inputTextBox}
                        fullWidth
                        disabled={!isEditing}
                    />
                </div>
                {isEditing && (
                    <div className={classes.buttonGroup}>
                        <Button
                            variant="outlined"
                            onClick={() => setIsEditing(false)}
                            sx={{
                                marginRight: "16px",
                                width: "25%",
                                padding: "0px 16px",
                                gap: "8px",
                                borderRadius: "8px",
                                color: "#000",
                                backgroundColor: "#fff",
                                boxShadow: "0px 2px 8px 0px #1E07511F",
                                border: "0px",
                            }}
                        >
                            {Object.keys(formik.values).length > 0 ? "Discard" : "Cancel"}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={{ width: "75%" }}
                            disabled={formik.isSubmitting || !formik.dirty}
                        >
                            Save Changes
                        </Button>
                    </div>
                )}
            </form>
        </Container>
    );
};

export default AboutMeEditForm;