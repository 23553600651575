const sessionsDemo = {
    "dasds-2222-dasd-122rf": {
        id: "dasds-2222-dasd-122rf",
        title: 'Checkup for Max',
        dateTime: '2024-06-04, 1:00 PM',
        status: 'completed',
        transcription: 'Max is in good health. No major concerns, but we will keep an eye on his diet.',
        summary: { Simple: "", Detailed: "" },
        transcriptionTime: 0,
    },
    "21jijo-dsafdsf1-fasfsaf-122rf": {
        id: "21jijo-dsafdsf1-fasfsaf-122rf",
        title: 'Checkup for Bella',
        dateTime: '2024-06-04, 1:00 PM',
        status: 'upcoming',
        transcription: 'Bella’s last checkup showed some dental tartar. We’ll evaluate if a cleaning is needed.',
        summary: { Simple: "", Detailed: "" },
        transcriptionTime: 0,
    },
    "21jijo-dsafdsf1-22112-122rf": {
        id: "21jijo-dsafdsf1-22112-122rf",
        title: 'Checkup for Charlie',
        dateTime: '2024-06-04, 1:00 PM',
        status: 'upcoming',
        transcription: 'Charlie has been very active. No health issues noted at the last visit.',
        summary: { Simple: "", Detailed: "" },
        transcriptionTime: 0,
    },
    "21jijo-easdsa-22112-122rf": {
        id: "21jijo-easdsa-22112-122rf",
        title: 'Checkup for Lucy',
        dateTime: '2024-06-04, 1:00 PM',
        status: 'upcoming',
        transcription: 'Lucy is doing well. We’ll check her weight gain since her last visit.',
        summary: { Simple: "", Detailed: "" },
        transcriptionTime: 0,
    },
    "21jijo-easdsa-dasd-122rf": {
        id: "21jijo-easdsa-dasd-122rf",
        title: 'Checkup for Ghorani',
        dateTime: '2024-06-04, 1:00 PM',
        status: 'upcoming',
        transcription: 'Ghorani had a mild cough last week. We’ll monitor her respiratory health closely.',
        summary: { Simple: "", Detailed: "" },
        transcriptionTime: 0,
    },
};

export default sessionsDemo;
