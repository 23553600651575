import React from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Icons from '@mui/icons-material';

const StyledButton = styled(Button)(({ theme }) => ({
    width: 'auto',
    height: '48px',
    minWidth: 'auto',
    [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
    },
    maxWidth: '203px',
    //   borderRadius: '65px',
    // padding: '10px 16px',
    //   border: '4px solid #fff',
    opacity: '1', // Assuming 0px in the opacity was a typo
    color: '#7019FF', // Assuming the text color should be white for contrast
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        backgroundColor: '#F6F7FB',
    },
}));

const CustomTreetButtonHollow = ({
    children,
    icon,
    variant = 'text',
    color = 'primary',
    size = 'medium',
    fullWidth = true,
    onClick,
    type,
    ...props
}) => {
    const IconComponent = icon ? Icons[icon] : null;
    if (type === 'pet') {
        return (
            <StyledButton
                variant={variant}
                color={color}
                size={size}
                fullWidth={fullWidth}
                backgroundColor="#FFF"
                startIcon={IconComponent ? <IconComponent /> : null}
                type="submit"
                onClick={onClick}
                {...props}
            >
                {typeof children === 'string' ? <Typography style={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '19.5px',
                    textAlign: 'center',
                    textWrap: 'nowrap',
                    // paddingRight: '16px',
                }}> {children} </Typography> : children}
            </StyledButton>
        );
    }
    return (
        <StyledButton
            variant={variant}
            color={color}
            size={size}
            fullWidth={fullWidth}
            startIcon={IconComponent ? <IconComponent /> : null}
            onClick={onClick}
            {...props}
        >
            {typeof children === 'string' ? <Typography style={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '19.5px',
                textAlign: 'center',
                textWrap: 'nowrap',
                // paddingRight: '16px',
            }}> {children} </Typography> : children}
        </StyledButton>
    );
};

export default CustomTreetButtonHollow;
