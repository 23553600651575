import { useState } from 'react';

const usePetStorage = (key, initialData = null) => {
    const [storedPets, setStoredPets] = useState(() => {
        try {
            // Get pet profiles from local storage
            const item = window.localStorage.getItem(key);
            const storedItems = item ? JSON.parse(item) : initialData;
            window.localStorage.setItem(key, JSON.stringify(storedItems)); // Initialize if no data
            return storedItems;
        } catch (error) {
            console.error("Error reading pet profiles from localStorage", error);
            return initialData;
        }
    });

    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedPets) : value;
            // Update state and localStorage
            setStoredPets(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error("Error saving pet profiles to localStorage", error);
        }
    };

    // CRUD Operations for Pet Profiles

    // Update a pet profile
    const updatePet = (petId, newPetData) => {
        setValue((prevPets) => ({
            ...prevPets,
            [petId]: { ...prevPets[petId], ...newPetData }
        }));
    };

    // Add a new pet profile
    const addPet = (newPet) => {
        // guid
        newPet.id = newPet.id || Math.random().toString(36).substr(2, 9); 
        setValue((prevPets) => ({
            ...prevPets,
            [newPet.id]: newPet
        }));
        return newPet.id;
    };

    // Delete a pet profile
    const deletePet = (petId) => {
        setValue((prevPets) => {
            const { [petId]: _, ...remainingPets } = prevPets; // Remove pet with petId
            return remainingPets;
        });
    };

    // Get a pet profile by ID
    const getPetById = (petId) => {
        return storedPets[petId] || null;
    };

    // Get all pets
    const getAllPets = () => {
        return Object.values(storedPets);
    };

    // Get pet age by date of birth to display as a string (e.g., "2 years" or "5 months")
    const getPetAgeByDOB = (dob) => {
        if (!dob) return ''; // Return empty string if no date of birth is provided
        const today = new Date();
        const birthDate = new Date(dob);

        // Calculate age in years
        let years = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();

        // Adjust years if the current date is before the birthday in the current year
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            years--;
        }

        // Calculate total days from birthDate to today
        const totalDays = Math.floor((today - birthDate) / (1000 * 60 * 60 * 24));

        // Prepare age string based on the conditions
        let ageString = '';
        if (years > 1) {
            ageString += `${years} year${years > 1 ? 's' : ''}`;
        } else if (totalDays > 31) {  // More than 31 days
            const months = Math.floor(totalDays / 30);
            ageString += `${months} month${months > 1 ? 's' : ''}`;
        }

        return ageString.trim(); // Return the final age string
    };


    return {
        pets: storedPets,
        updatePet,
        addPet,
        deletePet,
        getPetById,
        getAllPets,
        getPetAgeByDOB
    };
};

export default usePetStorage;
