
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_SPEECH_API_KEY;

/**
 * Convert Blob to Base64 for Google API
 */
const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result.split(',')[1];
      resolve(base64data);
    };
    reader.onerror = (error) => reject(error);
  });
};

const transcribeAudio = async (audioBlob) => {
  const audioBase64 = await blobToBase64(audioBlob);

  const requestBody = {
    config: {
      encoding: 'WEBM_OPUS',
      sampleRateHertz: 48000,
      languageCode: 'en-US',
      enableAutomaticPunctuation: true,
    },
    audio: {
      content: audioBase64,
    },
  };

  try {
    const response = await fetch(
      `https://speech.googleapis.com/v1/speech:recognize?key=${GOOGLE_API_KEY}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      }
    );

    const data = await response.json();
    if (response.ok && data.results && data.results.length > 0) {
      return data.results.map((result) => result.alternatives[0].transcript).join(' ');
    } else {
      console.error('API response:', data);
      throw new Error(data.error?.message || 'Error in transcription request');
    }
  } catch (error) {
    console.error('Error in Google Speech-to-Text API:', error);
    throw error;
  }
};

export { transcribeAudio };