import { useState } from 'react';
const usePetParentStorage = (key, initialData = null) => {
    const [storedPetParents, setStoredPetParents] = useState(() => {
        try {
            // Get pet parent profiles from local storage
            const item = window.localStorage.getItem(key);
            const storedItems = item ? JSON.parse(item) : initialData;
            window.localStorage.setItem(key, JSON.stringify(storedItems)); // Initialize if no data
            return storedItems;
        } catch (error) {
            console.error("Error reading pet parent profiles from localStorage", error);
            return initialData;
        }
    });

    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedPetParents) : value;
            // Update state and localStorage
            setStoredPetParents(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error("Error saving pet parent profiles to localStorage", error);
        }
    };

    // CRUD Operations for Pet Parent Profiles

    // Update a pet parent profile
    const updatePetParent = (parentId, newParentData) => {
        setValue((prevParents) => ({
            ...prevParents,
            [parentId]: { ...prevParents[parentId], ...newParentData }
        }));
    };

    // Add a new pet parent profile
    const addPetParent = (newParent) => {
        // guid
        newParent.id = newParent.id || Math.random().toString(36).substr(2, 9); 
        setValue((prevParents) => ({
            ...prevParents,
            [newParent.id]: newParent
        }));
        return newParent.id;
    };

    // Delete a pet parent profile
    const deletePetParent = (parentId) => {
        setValue((prevParents) => {
            const { [parentId]: _, ...remainingParents } = prevParents; // Remove parent with parentId
            return remainingParents;
        });
    };

    // Get a pet parent profile by ID
    const getPetParentById = (parentId) => {
        return storedPetParents[parentId] || null;
    };

    // Get all pet parents
    const getAllPetParents = () => {
        return Object.values(storedPetParents);
    };

    return {
        storedPetParents,
        updatePetParent,
        addPetParent,
        deletePetParent,
        getPetParentById,
        getAllPetParents
    };
};

export default usePetParentStorage;
