import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import CustomTreetButton from '../components/Buttons/CustomTreetButton.jsx';
import SessionList from '../components/SessionsList/SessionsList.jsx';
import PetsIcon from '@mui/icons-material/Pets';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Container } from '@mui/material';
import Header from '../components/Header/header.jsx';
import useSessionStorage from '../managers/sessionsManager.jsx';
import dayjs from 'dayjs';
import { paths } from '../data/constants.js';
const useStyles = makeStyles((theme) => ({
    title: {
        '&.MuiTypography-root': {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24.38px',
            textAlign: 'left',
            color: theme.palette.text.primary,
        },
    },
    sessionsTitleContainer: {
        marginTop: '24px',
        marginBottom: '16px',
    },
    sessionsValue: {
        color: '#7019FF',
        paddingLeft: '12px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
    },
    noSessionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        color: '#7D7D97',
    },
    sessionsListContainer: {
        width: '100%',
        alignItems: 'flex-start',
    },
    newSessionButtonWrapper: {
        // center the button at the bottom of the screen
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            position: 'fixed',
            bottom: '0dvh',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            backgroundColor: 'var(--White, #FFFFFF)',
            padding: '16px 24px',
            width: "100%",
            justifyContent: "center",
            alignItems: 'center',
            borderTop: '1px solid var(--Mid-Blue, #E3E8FF)'
        },
    },
    logo: {
        width: '60px',
    },
    Container: {
        '&.MuiContainer-root': {
            backgroundColor: theme.palette.background.notes,
            marginRight: '0px',
            marginLeft: '0px',
            maxWidth: '100%',
        },
    },
}));



const HomeHeader = ({ navigate, classes }) => {
    return (
        <div onClick={() => navigate(paths.notes)} style={{ cursor: "pointer" }}>
            <img className={classes.logo} src={`${process.env.PUBLIC_URL}/Treet.svg`} alt='logo' />
        </div>
    );
};

const NoteHome = () => {
    // Styles
    const classes = useStyles();
    const navigate = useNavigate();
    
    // States
    const [sessions, updateSession, addSession, deleteSession] = useSessionStorage('sessions', {});
    const now = new Date();
    
    // Functions
    const handleSessionDelete = (sessionId) => {
        deleteSession(sessionId);
        toast.success('Session deleted');
    };

    const handleSessionTitleUpdate = (sessionId, newTitle) => {
        updateSession(sessionId, { title: newTitle });
    };

    const createNewSession = () => {
        const newSessionId = uuidv4();
        const newSession = {
            id: newSessionId,
            title: `Untitled`,
            transcription: '',
            summary: '',
            dateTime: dayjs(now).format('YYYY-MM-DD, hh:mm A'),
            status: 'upcoming',
        };
        addSession(newSession);
        navigate(paths.session(newSessionId));
    };


    return (
        <>
            <Header>
                <HomeHeader classes={classes} navigate={navigate} />
            </Header>
            <Container className={classes.Container}>
                <div className={classes.contentContainer}>
                    <Grid container className={classes.sessionsTitleContainer} alignItems="center" justifyContent="space-between">
                        <Typography className={classes.title} >Sessions<span className={classes.sessionsValue}>{Object.keys(sessions).length || 0}</span></Typography>
                        <div className={classes.newSessionButtonWrapper}>
                            <CustomTreetButton
                                icon="Add"
                                onClick={() => createNewSession()}
                            >
                                New Session
                            </CustomTreetButton>
                        </div>
                    </Grid>
                    {Object.keys(sessions).length !== 0 ? (
                        <Grid container className={classes.sessionsListContainer}>
                            <SessionList sessions={Object.values(sessions)}
                                handleSessionDelete={handleSessionDelete}
                                handleSessionUpdate={handleSessionTitleUpdate}
                            />
                        </Grid>
                    ) :
                        <div className={classes.noSessionsContainer}>
                            <PetsIcon color='#7D7D97' />
                            <Typography>No sessions yet</Typography>
                        </div>
                    }
                </div>
            </Container>
        </>
    );
};

export default NoteHome;
