import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { paths } from '../../data/constants';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    header: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        width: '100dvw',
    },
    title: {
        "&.MuiTypography-root": {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24.38px',
        },
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            textAlign: 'center',
            alignItems: 'center',
        }
    },
    backButton: {
        '&.MuiButtonBase-root': {
            [theme.breakpoints.down('sm')]: {
                marginRight: 'auto',
            }
        }
    },
    tabsContainer: {
        flexGrow: 1,
    },
    tabContent: {
        padding: theme.spacing(2),
    },
    tabTitle: {
        '&.MuiTab-root': {
            fontSize: '14px',
            lineHeight: '17.07px',
            color: "#7D7D97",
            fontWeight: 500,
            textTransform: 'none',
        }
    },
    activeTab: {
        '&.MuiTab-root': {
            fontWeight: 600,
            color: "#1F1F2A"
        }
    },
    indicator: {
        backgroundColor: "#1F1F2A",
    },
    petHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: 48,
        padding: "12px 16px"
    },
    logo: {
        width: '60px',
    },
}));

const HomeHeader = ({ navigate, classes }) => {
    return (
        <div onClick={() => navigate(paths.pets)} className={classes.logoContainer} style={{ cursor: "pointer" }}>
            <img className={classes.logo} src={`${process.env.PUBLIC_URL}/Treet.svg`} alt='logo' />
        </div>
    );
};


const PetsHeader = ({ setTabIndex, tabIndex }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    return (
        <div className={classes.petHeaderContainer}>
            <HomeHeader classes={classes} navigate={navigate} />
            <div className={classes.tabsContainer}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="#1F1F2A"
                    TabIndicatorProps={{ style: { background: "#1F1F2A" } }}
                    variant="fullWidth"
                >
                    <Tab className={`${classes.tabTitle} ${tabIndex === 0 ? classes.activeTab : ''}`} label="Pets" />
                    <Tab className={`${classes.tabTitle} ${tabIndex === 1 ? classes.activeTab : ''}`} label="Upcoming" />
                    <Tab className={`${classes.tabTitle} ${tabIndex === 2 ? classes.activeTab : ''}`} label="About" />
                </Tabs>
            </div>
        </div>
    );
};

export default PetsHeader;
