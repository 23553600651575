import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '16px',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '8px',
        gap: '8px',
    },
    iconText: {
        fontSize: '18px',
        color: "#1F1F2A",
        lineHeight: '21.7px',
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    sectionTitle: {
        paddingTop: '60%',
        paddingBottom: '2%',
        textAlign: 'center',
    },
    inputTextBox: {
        padding: '16px',
        marginTop: '8px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
    },
    textIdFields: {
        textAlign: 'left',
        color: '#7D7D97',
        fontSize: 12,
        lineHeight: '18.6px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    detailSection: {
        marginBottom: '24px',
    },
    googleButton: {
        width: '100%',
        padding: '16px',
        borderRadius: '8px',
        fontSize: '16px',
        lineHeight: '24.8px',
        fontWeight: 600,
    },
}));

const PasswordSavedSuccessContent = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.detailSection}>
                <Typography variant="h4" component="div" className={classes.sectionTitle}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                        <img src={`${process.env.PUBLIC_URL}/authIcons/lock.svg`} alt="login" />
                    </div>

                    <span style={{fontWeight: 600}}>Password saved successfully</span>
                </Typography>

                <Typography variant="body1" component="div" className={classes.iconContainer}>
                    <span className={classes.iconText}>Thank you for verifying your email and creating a password for your account.</span>
                </Typography>
            </div>
        </>
    );
};

export default PasswordSavedSuccessContent;
