import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { makeStyles } from '@mui/styles';

// Define styles for beat animation
const useStyles = makeStyles(theme => ({
  beatAnimation: {
    animation: '$beat 1.5s infinite ease-in-out', // Use the defined keyframes with 1.5s duration
    color: 'gold', // Color for the AutoAwesomeIcon
    fontSize: '48px', // You can adjust the size as needed
  },
  '@keyframes beat': {
    '0%, 100%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.6)',
    },
  },
}));

const AwesomeLoader = () => {
  const classes = useStyles();

  return (
    <div>
      <AutoAwesomeIcon className={classes.beatAnimation} />
    </div>
  );
};

export default AwesomeLoader;
