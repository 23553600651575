import React from 'react';
import { Card, CardContent, Avatar, Typography, Chip, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from '@mui/styles';
import ReusableMenuPets from '../Menus/ReusableThreeMenuPets.jsx';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.jsx';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import { useNavigate } from 'react-router-dom';
import { paths, PetsTagsStyle as TagsStyle } from '../../data/constants.js';
// Define useStyles for styling
const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 'fit-content',
    // maxHeight: 200,
    padding: '16px',
    borderRadius: '12px !important',
    marginBottom: '16px',
    boxShadow: '0px 4px 19.8px 0px #0000001A',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    // space between
    justifyContent: 'space-between',
    // height: 25,
  },
  petName: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '8px',
    "&.MuiTypography-root": {
      // 16px
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '24.8px',
      color: "#1F1F2A"
    },
  },
  content: {
    display: 'flex',
    // take all available space
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    // maxHeight: 64,
    "&.MuiCardContent-root": {
      padding: "0px !important",
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '6px',
    justifyContent: 'center',
  },
  chip: {
    maxWidth: 220,
    height: 27,
    borderRadius: '6px !important',
    pointerEvents: 'none',
    "&.MuiChip-root": {
      padding: '6px 8px',
      fontSize: '0.75rem', // 12px
      fontWeight: 600,
      lineHeight: '14.63px',
    },
    "& .MuiChip-label": {
      padding: 0,
    },
  },
  moreIcon: {
    // fix to top right with 12px padding top and 8px padding right from card
    "&.MuiIconButton-root": {
      position: 'absolute',
      top: 12,
      right: 8,
      padding: 0,
    },

  }
}));



const PetCard = ({ pet, handlePetDelete, handlePetUpdate }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    handlePetDelete(pet.id);
    handleCloseDialog();
  }


  const menuItems = [
    {
      label: 'Edit profile',
      icon: <BorderColorOutlinedIcon />,
      action: () => navigate(paths.petCreateEdit, { state: { petID: pet.id } }),
    },
    {
      label: 'Share profile',
      icon: <IosShareOutlinedIcon />,
      action: () => console.log('share action'),
    },
    {
      label: 'Delete profile',
      icon: <DeleteOutlinedIcon />,
      action: handleOpenDialog
    },
  ];
  return (
    <>
      <Card className={classes.card} onClick={() => navigate(paths.petProfile(pet.id))}>
        <CardContent className={classes.content}>
          <div className={classes.header}>
            <Avatar
              alt={pet.name}
              src={pet.photo || process.env.PUBLIC_URL + '/logo-gray.svg'} // Fallback to default avatar
              variant="square"
              // Square avatar
              sx={{
                width: 80,
                height: 80,
                border: "2.22px solid #fff",
                boxShadow: '0px 2px 12px 0px #00000026',
                borderRadius: '12px',
                marginBottom: '16px',
              }}
            />
          </div>

          <Typography variant="h6" component="div" className={classes.petName}>
            {pet.name || 'Unknown'} {/* Fallback for missing name */}
          </Typography>

          {/* Tags */}
          <div className={classes.chips}>
            {/* Type Chip */}
            {pet.pet_type && (
              <Chip
                label={pet.pet_type[0].toUpperCase() + pet.pet_type.slice(1)} // Capitalize first letter
                style={TagsStyle.type[pet.pet_type] || TagsStyle.default} // Fallback to default styling
                className={classes.chip}
              />
            )}

            {/* Breed Chip */}
            {pet.breed && (
              <Chip
                label={pet.breed[0].toUpperCase() + pet.breed.slice(1)} // Capitalize first letter
                style={TagsStyle.breed || TagsStyle.default}
                className={classes.chip}
              />
            )}

            {/* Sex Chip */}
            {pet.sex && (
              <Chip
                label={pet.sex[0].toUpperCase() + pet.sex.slice(1)} // Capitalize first letter
                style={TagsStyle.sex[pet.sex] || TagsStyle.default}
                className={classes.chip}
              />
            )}
          </div>

          {/* Three Dot Menu */}
          <IconButton
            className={classes.moreIcon}
            onClick={(e) => {
              e.stopPropagation(); // Prevent the click from propagating to the card
              // Add any functionality to open the menu here
            }}
          >
            <ReusableMenuPets pet={pet} menuItems={menuItems} />
          </IconButton>
        </CardContent>

      </Card>
      <ConfirmationDialog
        open={dialogOpen}
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmDelete}
        title={`Delete "${pet.name}"?`}
        content="You will not be able to retrieve this pet profile once it has been deleted."
        confirmLabel="Delete Pet Profile"
      />
    </>
  );
};

export default PetCard;
