import React, { useEffect, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { paths } from '../../../data/constants';
import { Container } from '@mui/material';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { Chip } from '@mui/material';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import { useFormik } from 'formik';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '16px',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '8px',
        width: '48px',
        height: '48px',
    },
    iconText: {
        '&.MuiTypography-root': {
            fontSize: '14px',
            fontWeight: 600,
            color: "#1F1F2A",
            lineHeight: '21.7px',
        }
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    inputTextBox: {
        padding: '16px',
        // width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
        flexGrow: 1,
        '& .MuiInputBase-input': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    textIdFields: {
        textAlign: 'left',
        color: '#7D7D97',
        fontSize: 12,
        lineHeight: '18.6px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    chip: {
        height: '24px',
        borderRadius: '32px',
        fontSize: '12px',
        lineHeight: '18.6px',
        fontWeight: 600,
        background: 'var(--Light-Grey, #F6F7FB) !important',
        width: 'fit-content',
    },
    detailSection: {
        marginBottom: '16px',
        alignItems: 'center',

        width: '100%',
    },
    detailSectionTitle: {
        marginBottom: '24px',
    },
    detailTitle: {
        "&.MuiTypography-root": {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.94px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    chipLabel: {
        "&.MuiChip-label": {
            padding: '8px',
        },
    },
    breedGroup: {
        display: 'flex',
        gap: '8px',
        width: '100%',
    },
}));

const SocialLinks = [
    {
        platform: 'facebook',
        image: `${process.env.PUBLIC_URL}/socialIcons/facebook.svg`,
    },
    {
        platform: 'instagram',
        image: `${process.env.PUBLIC_URL}/socialIcons/instagram.svg`,
    },
    {
        platform: 'twitter',
        image: `${process.env.PUBLIC_URL}/socialIcons/twitter.svg`,
    },
    {
        platform: 'youtube',
        image: `${process.env.PUBLIC_URL}/socialIcons/youtube.svg`,

    },
    {
        // tiktok
        platform: 'tiktok',
        image: `${process.env.PUBLIC_URL}/socialIcons/tiktok.svg`,
    }
]

const PetSocialForm = ({ formik, ref }) => {
    const classes = useStyles();

    return (
        <div style={{ width: "100%" }} ref={ref}>
            <div className={classes.detailSectionTitle}>
                <Typography align="left" className={classes.detailTitle}>
                    Social Links
                </Typography>
            </div>

            {SocialLinks && SocialLinks.map(({ platform, image }, index) => {
                // Only render if url is valid
                if (!platform) return null;

                return (
                    <Grid container wrap="nowrap" key={index} direction="row" className={classes.detailSection}>
                        <Grid item className={classes.iconContainer} sx={{ width: 48 }}> {/* Adjust width as needed */}
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img src={image} alt={platform} style={{
                                    width: 24,
                                    height: 24,
                                }} />
                            </Box>
                        </Grid>
                        <Grid item xs> {/* This will take the remaining width */}
                            <TextField
                                fullWidth
                                name={`social_links.${platform}`} // update to access dynamic field correctly
                                value={formik.values?.social_links?.[platform] || ''}
                                placeholder={`Enter ${platform[0].toUpperCase() + platform.slice(1)} URL`}
                                onChange={formik.handleChange}
                                className={classes.inputTextBox}
                                error={formik.touched?.social_links?.[platform] && Boolean(formik.errors?.social_links?.[platform])}
                                helperText={formik.touched?.social_links?.[platform] && formik.errors?.social_links?.[platform]}
                            />
                        </Grid>
                    </Grid>

                );
            })}


        </div>
    );
};

export default PetSocialForm;
