import React, { useEffect, useState, useRef } from 'react';
import PetGeneralDetails from '../components/PetProfileSections/PetGeneralDetails';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import usePetStorage from '../managers/petsManager';
import { pets as petsDemo } from '../data/pets';
import PetProfileDivider from '../components/Dividers/PetProfileDivider';
import Header from '../components/Header/header';
import PetProfileHeader from '../components/Header/petProfileHeader';


const PetProfile = () => {
    const { profileId } = useParams();
    // const [pet, setPet] = useState({});
    // Fetch pet data from the server
    // useEffect(() => {
    //     fetchPet(petId).then((data) => {
    //         setPet(data);
    //     });
    // }, [petId]);

    const [tabIndex, setTabIndex] = useState(0);

    // scroll to top when component is mounted
    const aboutRef = useRef(null);
    const parentsRef = useRef(null);
    const medicalRef = useRef(null);
    // Function to handle tab change and scroll to section
    const handleTabChange = (index) => {
        setTabIndex(index);
        const sectionRefs = [aboutRef, parentsRef, medicalRef];
        sectionRefs[index].current.scrollIntoView({ behavior: 'smooth' });
    };


    const {
        pets,
        updatePet,
        addPet,
        deletePet,
        getPetById,
        getAllPets
    } = usePetStorage('pets', petsDemo); // 'pets' key for localStorage
    console.log(profileId);
    console.log(getAllPets());
    const pet = getPetById(profileId);

    console.log(pet);
    return (
        <div>
            <Header>
                <PetProfileHeader
                    pet={pet}
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    onTabChange={handleTabChange} // Pass the handler
                />
            </Header>
            <Container maxWidth="md" sx={{ padding: '24px 20px' }}>
                <div ref={aboutRef}>
                    <PetGeneralDetails pet={pet} />
                    <PetProfileDivider />
                </div>
                <div ref={parentsRef}>
                    {/* Parents Section */}
                </div>
                <div ref={medicalRef}>
                    {/* Medical Section */}
                </div>
            </Container>
        </div>
    );
};

export default PetProfile;