import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import SessionItem from './SessionItem.jsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    sessionsListContainer: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '80px',
        },
    },
    sessionsValue: {
        color: 'var(--Bright-Purple, #7019FF)',
        paddingLeft: '12px',
    }
}));

const SessionList = ({ sessions, handleSessionDelete, handleSessionUpdate }) => {
    const classes = useStyles();
    // sort sessions by date and time
    sessions.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
    return (
        <Grid container className={classes.sessionsListContainer} alignItems="center" justifyContent="space-between">
            {sessions?.map((session) => (
                <SessionItem
                    key={session.id}
                    session={session}
                    handleSessionDelete={() => handleSessionDelete(session.id)}
                    handleSessionUpdate={handleSessionUpdate}
                />
            ))}
        </Grid>
    );
};

SessionList.propTypes = {
    sessions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ).isRequired,
    handleSessionDelete: PropTypes.func.isRequired,
    handleSessionUpdate: PropTypes.func.isRequired,
};

export default SessionList;
