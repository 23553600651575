import './App.css';
import React, {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Routes, // instead of "Switch"
  Route
} from "react-router-dom";
import NoteHome from './pages/NoteHome';
import PetHome from './pages/PetHome';
import SessionPage from './pages/Session';
import PetProfilePage from './pages/petProfilePage';
import PetCreationFlow from './pages/PetCreationFlow';
import PetCreateEdit from './pages/petCreationEdit';
import VerifyEmail from './pages/VerifyEmail';
import Login from './pages/Login';
import Password from './pages/Password';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordSuccess from './pages/ResetPasswordSuccess';
import PasswordSavedSuccess from './pages/PasswordSavedSuccess';
import { paths } from './data/constants';

function App() {
  function ExternalRedirect() {
    useEffect(() => {
      window.location.href = 'https://www.treet.ai';
    }, []);
  
    return null; // This component doesn't render anything
  }
  
  return (
    <Router>
      <Routes>
        {/* navigate to another url site */}
        <Route path={paths.home} element={<ExternalRedirect />} />
        <Route path={paths.notes}>
          <Route index element={<NoteHome />} />
          <Route path={paths.session()} element={<SessionPage />} />
        </Route>
        <Route path={paths.pets}>
          <Route index element={<PetHome />} />
          <Route path={paths.petProfile()} element={<PetProfilePage />} />
          <Route path={paths.petCreateEdit} element={<PetCreateEdit />} />
          <Route path={paths.petCreate} element={<PetCreateEdit />} />
          <Route path={paths.petCreationFlow} element={<PetCreationFlow />} />
        </Route>
        <Route path={paths.verifyEmail} element={<VerifyEmail />} />
        <Route path={paths.login} element={<Login />} />
        <Route path={paths.password} element={<Password />} />
        <Route path={paths.resetPassword} element={<ResetPassword />} />
        <Route path={paths.passwordResetSuccess} element={<ResetPasswordSuccess />} />
        <Route path={paths.passwordSavedSuccess} element={<PasswordSavedSuccess />} />
      </Routes>
    </Router>
  );
}

export default App;
