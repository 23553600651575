import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import Header from '../components/Header/header.jsx';
import DefaultHeader from '../components/Header/defaultHeader.jsx';
import ResetPasswordForm from '../components/Auth/ResetPasswordForm.jsx';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    title: {
        '&.MuiTypography-root': {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24.38px',
            textAlign: 'left',
            color: theme.palette.text.primary,
        },
    },
    petsTitleContainer: {
        marginTop: '24px',
        marginBottom: '16px',
    },
    petsValue: {
        color: '#7019FF',
        paddingLeft: '12px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    navContainer: {
        backgroundColor: '#f8f9fa',
        boxShadow: '0px 4px 15px 0px #0000000D',
        borderBottom: '1px solid rgba(227, 232, 255, 1)',
        width: '100%',
        minHeight: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemsContainer: {
        // maxWidth: '900px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const handleSavePassword = () => {
    // get from localStorage
    const auth = localStorage.getItem('auth');
    if (!auth) {
        toast.error('Invalid request');
        return;
    }

    // TODO: call save password API

    // clear localStorage
    localStorage.removeItem('auth');
}

const ResetPassword = () => {
    const classes = useStyles();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const token = queryParams.get('t');

    const [disabled, setDisabled] = React.useState(true);
    
    useEffect(() => {
        if (!email || !token) return;

        // set in localStorage as a json
        localStorage.setItem('auth', JSON.stringify({
            flow: 'verify-email',
            data: {
                email,
                token,
            }
        }));
    }, [email, token]);

    return (
        <>
            <Header navContainer={classes.navContainer} itemsContainer={classes.itemsContainer}>
                <DefaultHeader />
            </Header>
            <Container maxWidth="lg">
                <div className={classes.contentContainer}>
                    <ResetPasswordForm email={email} token={token} setDisabled={setDisabled} />
                </div>
            </Container>
            <div style={{ position: 'fixed', bottom: '0', width: '100%', padding: '16px', background: '#fff', boxShadow: '0 -2px 10px rgba(0,0,0,0.1)' }}>
                <button style={{
                    width: '100%',
                    padding: '16px',
                    background: disabled ? '#A0A0A0' : '#7019FF',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '8px',
                    fontSize: '16px',
                    fontWeight: '600',
                    cursor: 'pointer',
                }} disabled={disabled}>
                    Save Password
                </button>
            </div>
        </>
    );
};

export default ResetPassword;
