import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Drawer, List, ListItem, ListItemIcon, ListItemText, Grid, Typography, ListItemButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles({
    menuItem: {
        '&.MuiMenuItem-root': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '19.5px',
            color: 'var(--Faux-Black, rgba(31, 31, 42, 1))',
            '&:hover': {
                backgroundColor: 'var(--Light-Purple, #F8F7FF)',
            }
        }
    },
    title: {
        '&.MuiTypography-root': {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.94px',
            color: 'var(--Faux-Black, rgba(31, 31, 42, 1))',
            marginBottom: '8px',
            TextAlign: 'left',
        }
    },
    petsDate: {
        '&.MuiTypography-root': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '19.5px',
            color: '#7D7D97',
        }
    },
    drawerHeader: {
        padding: '16px',
    },
    listItem: {
        '&.MuiListItem-root': {
            padding: '14px 16px',
            // all children of the list item will have this color
            '& .MuiTypography-root': {
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '19.5px',
                color: 'var(--Faux-Black, rgba(31, 31, 42, 1))',
                '&:hover': {
                    backgroundColor: 'var(--Light-Purple, #F8F7FF)',
                }
            },
        }
    },
    drawerPaper: {
        borderRadius: '8px 8px 0 0',
    },
    iconContainer: {
        "& .MuiSvgIcon-root": {
            marginRight: '16px',
            width: '20px'
        }
    }
});

const ReusableMenuPets = ({ pet, menuItems }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleClick = (event) => {
        if (isMobile) {
            setDrawerOpen(true);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
    };

    const handleMenuAction = (action) => {
        action();
        handleCloseMenu();
        handleCloseDrawer();
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            {isMobile ? (
                <Drawer classes={{ paper: classes.drawerPaper }}
                    anchor="bottom" open={drawerOpen} onClose={handleCloseDrawer}>
                    <Grid className={classes.drawerHeader} container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography
                                className={classes.title}
                            >
                                {pet?.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseDrawer}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <List>
                        {menuItems.map((item, index) => (
                            <ListItem className={classes.listItem} key={index} >
                                <ListItemButton disabled={item.disable} onClick={() => handleMenuAction(item.action)}>
                                    <ListItemIcon >{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.label} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            ) : (
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    {menuItems.map((item, index) => (
                        <MenuItem key={index} className={classes.menuItem} disabled={item.disable} onClick={() => handleMenuAction(item.action)}>
                            <span className={classes.iconContainer}>{item.icon}</span>
                            {item.label}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </>
    );
};

export default ReusableMenuPets;
