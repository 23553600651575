import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '16px',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '8px',
        gap: '8px',
    },
    iconText: {
        fontSize: '14px',
        fontWeight: 650,
        color: "#1F1F2A",
        lineHeight: '21.7px',
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    validationErrorText: {
        fontSize: '14px',
        fontWeight: 650,
        color: "#FF6058",
        lineHeight: '21.7px',
    },
    noticeBox: {
        padding: '20px',
        marginTop: '24px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: '#F3F1FF',
    },
    sectionTitle: {
        paddingTop: '20%',
        paddingBottom: '5%',
    },
    inputTextBox: {
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
    },
    textIdFields: {
        textAlign: 'left',
        color: '#7D7D97',
        fontSize: 12,
        lineHeight: '18.6px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    detailSection: {
        marginBottom: '24px',
    },
}));

const EmailVerificationForm = ({ email, token, setDisabled }) => {
    const classes = useStyles();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    
    const [showPassword1, setShowPassword1] = useState(false);
    const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
    const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);

    const [showPassword2, setShowPassword2] = useState(false);
    const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
    const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

    useEffect(() => {
        if (!email || !token) {
            toast.error('Invalid email or token');
            return;
        }
        
        if (password === confirmPassword && password.length >= 8) {
            setDisabled(false);

            // set local storage
            localStorage.setItem('auth', JSON.stringify({
                flow: 'verify-email',
                data: {
                    email,
                    token,
                    password,
                }
            }));
        } else {
            setDisabled(true);
        }
    }, [email, token, password, confirmPassword, setDisabled]);

    useEffect(() => {
        if (password.length === 0 && confirmPassword.length === 0) {
            // when both are empty
            setPasswordsMatch(true);
        } else if (password.length > 0 && confirmPassword.length === 0) {
            // when password is filled but confirm password is empty
            setPasswordsMatch(true);
        } else if (password === confirmPassword && password.length >= 8) {
            // when both are filled and they match
            setPasswordsMatch(true);
        } else {
            setPasswordsMatch(false);
        }
    }, [password, confirmPassword]);

    return (
        <>
            <div className={classes.detailSection}>

                {/* notice box */}
                {email && (
                    <div className={classes.noticeBox}>
                        <Typography align="left" className={classes.iconText}>
                            <img src={`${process.env.PUBLIC_URL}/authIcons/login-2.svg`} alt="login" style={{ 
                                paddingRight: '12px',
                                verticalAlign: 'middle',
                            }} />
                            <span style={{verticalAlign: 'middle'}}>Logged in as <span style={{
                                strokeWidth: '0.5px',
                                fontWeight: 650,
                            }}>{email}</span></span>
                        </Typography>
                    </div>
                )}

                {/* main title */}
                <Typography variant="h4" component="div" className={classes.sectionTitle}>
                    <span style={{fontWeight: 600}}>Create password for future logins</span>
                </Typography>
                
                {/* input fields */}
                <div className={classes.iconContainer}>
                    <Typography variant='subtitle2' align="left" className={classes.iconText}>
                        Create password
                    </Typography>
                </div>
                <TextField
                    align="left"
                    type={showPassword1 ? "text" : "password"} // <-- This is where the magic happens
                    onChange={(e) => setPassword(e.target.value)}
                    className={classes.inputTextBox}
                    placeholder="Create your password"
                    sx={{
                        cursor: 'text',// : 'pointer',
                        color: '#1F1F2A',// : '#7D7D97',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        fontWeight: 500,
                    }}
                    InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword1}
                                onMouseDown={handleMouseDownPassword1}
                                style={{ color: '#7019FF' }}
                            >
                                {showPassword1 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                    slotProps={{
                        inputLabel: {
                            shrink: false,
                        }
                    }}
                />

                <div className={classes.iconContainer} style={{marginTop: '10px'}}>
                    <Typography variant='subtitle2' align="left" className={classes.iconText}>
                        Re-enter password
                    </Typography>
                </div>
                <TextField
                    align="left"
                    type={showPassword2 ? "text" : "password"} // <-- This is where the magic happens
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className={classes.inputTextBox}
                    placeholder="Re-enter your password"
                    sx={{
                        cursor: 'text',// : 'pointer',
                        color: '#1F1F2A',// : '#7D7D97',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        fontWeight: 500,
                    }}
                    InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword2}
                                onMouseDown={handleMouseDownPassword2}
                                style={{ color: '#7019FF' }}
                            >
                                {showPassword2 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                    slotProps={{
                        inputLabel: {
                            shrink: false,
                        }
                    }}
                />

                {/* validation error */}
                { !passwordsMatch && (
                    <div className={classes.iconContainer} style={{marginTop: '0px'}}>
                        <Typography variant='subtitle2' align="left" className={classes.validationErrorText}>
                            * Password does not match
                        </Typography>
                    </div>
                )}
            </div>
        </>
    );
};

export default EmailVerificationForm;
