import openai from "./openAIClient";
import { DETAILED_SUMMARY_FORMAT, SIMPLE_SUMMARY_FORMAT } from '../data/constants.js'

export const getSummary = async (inputText, summaryType) => {
    try {
        let template = ""
        if (summaryType === "Simple") {
            template = SIMPLE_SUMMARY_FORMAT;
        } else if (summaryType === "Detailed") {
            template = DETAILED_SUMMARY_FORMAT;
        } else {
            return "Invalid summary type";
        }
        const response = await openai.chat.completions.create({
            model: 'gpt-3.5-turbo-0125',
            messages: [
                { role: 'system', content: 'You are a highly skilled veterinary AI assistant trained in language comprehension and summarization for veterinary clinics. You ONLY reply in JSON format. Your response is meant to assist Vets.' },
                { role: 'user', content: `Summarize the following transcription within the quotes:\n\n"${inputText}"\n NO EXPLANATION. NO QUOTES. DON'T SEND THE EXAMPLE TEMPLATE BACK. REPLACE fields without provided info with 'N/A'. ONLY populate if you're confident with the field value. FOLLOW THE FORMAT STRICTLY:\n\n${template}` },
            ],
            max_tokens: 500, // Adjust token limit as needed
        });

        return response.choices[0].message.content.trim();
    } catch (error) {
        console.error('Error fetching summary:', error);
    }
};