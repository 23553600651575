import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import Header from '../components/Header/header.jsx';
import DefaultHeader from '../components/Header/defaultHeader.jsx';
import EmailVerificationForm from '../components/Auth/EmailVerificationForm.jsx';
import { toast } from 'react-toastify';
import ApiClient from '../utils/client';

const api = new ApiClient();

const useStyles = makeStyles((theme) => ({
    title: {
        '&.MuiTypography-root': {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24.38px',
            textAlign: 'left',
            color: theme.palette.text.primary,
        },
    },
    petsTitleContainer: {
        marginTop: '24px',
        marginBottom: '16px',
    },
    petsValue: {
        color: '#7019FF',
        paddingLeft: '12px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    navContainer: {
        backgroundColor: '#f8f9fa',
        boxShadow: '0px 4px 15px 0px #0000000D',
        borderBottom: '1px solid rgba(227, 232, 255, 1)',
        width: '100%',
        minHeight: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemsContainer: {
        // maxWidth: '900px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconText: {
        fontSize: '14px',
        fontWeight: 650,
        color: "#1F1F2A",
        lineHeight: '21.7px',
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    noticeBox: {
        padding: '20px',
        marginTop: '24px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: '#F3F1FF',
    },
}));

const VerifyEmail = () => {
    const classes = useStyles();
    const location = useLocation();

    const [isVerified, setIsVerified] = React.useState(false);

    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const token = queryParams.get('t');

    const [disabled, setDisabled] = React.useState(true);
    
    useEffect(() => {
        if (!email || !token || isVerified) return;

        if (!api) throw new Error('API client not found');
        
        // make sure the email is not verified yet
        api.get(`cms/auth/is-verified?email=${email}`)
            .then((response) => {
                setIsVerified(response.verified);
            })
            .catch((error) => {
                toast.error(error.message);
            });

        if (isVerified) return;
        
        // set in localStorage as a json
        localStorage.setItem('auth', JSON.stringify({
            flow: 'verify-email',
            data: {
                email,
                token,
            }
        }));
    }, [email, token]);

    useEffect(() => {
        if (isVerified) {
            // clear localStorage
            localStorage.removeItem('auth');
        }
    }, [isVerified]);

    const handleSavePassword = () => {
        setDisabled(true);

        if (!api) throw new Error('API client not found');
    
        // get from localStorage
        const authStr = localStorage.getItem('auth');
        if (!authStr) {
            toast.error('Invalid request');
            setDisabled(false);
            return;
        }
        
        const auth = JSON.parse(authStr);
    
        if (!auth.data.email || !auth.data.password) {
            toast.error('Invalid request');
            setDisabled(false);
            return;
        }
    
        api.post(`cms/auth/save-password?`, {
            email: auth.data.email,
            password: auth.data.password,
        })
            .then((response) => {
                // clear localStorage
                localStorage.removeItem('auth');
    
                // only redirect if the local storage is cleared
                if (localStorage.getItem('auth')) {
                    toast.error('Failed to save password');
                    setDisabled(false);
                    return;
                }
    
                // redirect
                window.location.href = '/password-saved-success';
            })
            .catch((error) => {
                console.error(error);
                toast.error("Failed to save password");
                setDisabled(false);
            });
    }

    return (
        <>
            <Header navContainer={classes.navContainer} itemsContainer={classes.itemsContainer}>
                <DefaultHeader />
            </Header>
            <Container maxWidth="lg">
                <div className={classes.contentContainer}>
                
                    {isVerified && (
                        <div className={classes.noticeBox}>
                            <Typography align="left" className={classes.iconText}>
                                
                                <span style={{verticalAlign: 'middle'}}>Account <span style={{
                                    strokeWidth: '0.5px',
                                    fontWeight: 650,
                                }}>{email}</span> is already verified</span>
                            </Typography>
                        </div>
                    )}

                    {!isVerified && (
                        <EmailVerificationForm email={email} token={token} setDisabled={setDisabled} />
                    )}
                </div>
            </Container>

            {!isVerified && (
                <div style={{ position: 'fixed', bottom: '0', width: '100%', padding: '16px', background: '#fff', boxShadow: '0 -2px 10px rgba(0,0,0,0.1)' }}>
                    <button style={{
                        width: '100%',
                        padding: '16px',
                        background: disabled ? '#A0A0A0' : '#7019FF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '16px',
                        fontWeight: '600',
                        cursor: 'pointer',
                    }} disabled={disabled} onClick={handleSavePassword}>
                        Save Password
                    </button>
                </div>
            )}
        </>
    );
};

export default VerifyEmail;
